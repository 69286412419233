import gsap from 'gsap';
import {
	AnimationMixer,
	Clock,
	Group,
	Mesh,
	MeshPhongMaterial,
	MeshStandardMaterial,
	Quaternion,
	Vector3,
} from 'three';
import { World } from './World.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Shapes } from '../geometries/Shapes';
import { AP } from './AmmoPhysics.js';

const keyCodes = ['KeyW', 'KeyA', 'KeyS', 'KeyD', 'KeyK', 'KeyI', 'Space'];

// Lasers
const green = new MeshStandardMaterial({ color: 0x55ff55 });
const red = new MeshStandardMaterial({ color: 0xff5555 });

// Transform keycode to key
const toKey = (k) => k.replace('Key', '').toLowerCase();

const pos = new Vector3();
const quat = new Quaternion();

const clock = new Clock();

export class Drone {
	static mixer;
	static speed = 30;
	static rollAngle = 0.2;
	static yawSpeed = 2;
	static droneMass = 4;
	static upVelocity = 25;
	static downVelocity = -25;
	static bulletMass = 1000;
	static bulletVelocity = 60;
	static armed = true;
	static drone;
	static droneGroup = new Group();
	static tiltGroup = new Group();
	static keys = {
		w: false,
		a: false,
		s: false,
		d: false,
		k: false,
		i: false,
		space: false,
	};
	static v3 = new Vector3();
	static bodyMesh;
	static bt3 = null;
	static initialRotation;

	static init() {
		// Create reusable variables
		Drone.bt3 = new AP.Ammo.btVector3(0, 0, 0);

		Drone.createDrone();
		// Drone.watchArm();
		Drone.move();
		Drone.lasers();

		Drone.addDroneMass();

		World.addAnimationCallback(Drone.animate);

		// Drone.arm();
		// World.droneView();
	}

	static createDrone() {
		const loader = new GLTFLoader();
		loader.load(
			'drone/scene.gltf',
			(gltf) => {
				// Get scene group
				Drone.drone = gltf.scene;

				// Turn on shadows
				Drone.drone.traverse(function (object) {
					if (object instanceof Mesh) object.castShadow = true;
				});

				// Create animation mixer
				Drone.mixer = new AnimationMixer(gltf.scene);

				gltf.animations.forEach((clip) => {
					const action = Drone.mixer.clipAction(clip);
					action.play();
				});

				Drone.drone.scale.set(0.001, 0.001, 0.001);

				// Shift drone left a touch cause the milennium falcon is a bit off center
				// Drone.drone.translateX(0.035);
				// Rotate drone 90 degrees around the Y axis
				Drone.drone.rotateY(-Math.PI / 2);

				// Save initial rotation for reference
				Drone.initialRotation = Drone.drone.rotation.clone();

				// Create nested group structure:
				// droneGroup → tiltGroup → drone
				Drone.tiltGroup.add(Drone.drone);
				Drone.droneGroup.add(Drone.tiltGroup);
				Drone.droneGroup.position.set(25, 5, 35);
				World.scene.add(Drone.droneGroup);
			},
			(xhr) => {
				// console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
			},
			(error) => console.error(error)
		);
	}

	static lasers() {
		document.addEventListener('keypress', (e) => {
			if (e.code !== 'Space') return;
			Drone.shoot();
		});
	}

	static shoot() {
		const { bt3, bulletVelocity, droneGroup, bulletMass } = Drone;
		const geo = [0.2, 0.2, 0.6];
		const btq = new AP.Ammo.btQuaternion();
		const ahead = new Vector3(0, 0, 1);

		const material = Math.random() < 0.5 ? green : red;
		const bullet = Shapes.box(...geo, pos, quat, material, bulletMass);
		bullet.name = 'bullet';
		bullet.userData.velocity = bulletVelocity;

		const { body } = bullet.userData;

		// Remove after x seconds
		setTimeout(() => AP.remove(bullet), 10000);

		// No spinning
		bt3.setValue(0, 0, 0);
		body.setGravity(bt3);
		body.setAngularFactor(0, 0, 0);

		// Calculate forward vector
		const forward = ahead
			.clone()
			.applyQuaternion(droneGroup.quaternion)
			.normalize();

		const offset = forward.clone().multiplyScalar(0.9);
		const final = droneGroup.position.clone().add(offset);

		// Set bullet position
		bt3.setValue(final.x, final.y, final.z);
		body.getWorldTransform().setOrigin(bt3);

		// Set bullet rotation
		const { x, y, z, w } = droneGroup.quaternion;
		btq.setValue(x, y, z, w);
		body.getWorldTransform().setRotation(btq);

		// Set velocity
		forward.multiplyScalar(bulletVelocity);
		bt3.setValue(forward.x, forward.y, forward.z);
		body.setLinearVelocity(bt3);
	}

	static watchArm() {
		document.addEventListener('keypress', (e) => {
			if (e.code !== 'Space') return;

			// Land or hover the drone
			World.droneView();
			Drone.armed = !Drone.armed;
			Drone.armed ? Drone.arm() : Drone.disarm();
		});
	}

	static arm() {
		const { bodyMesh, verticalBoost, bt3 } = Drone;
		const { body } = bodyMesh.userData;

		bt3.setValue(0, 0, 0);
		body.setGravity(bt3);

		// Small takeoff
		verticalBoost('up');
		setTimeout(() => verticalBoost('stop'), 100);
	}

	static disarm() {
		const { bodyMesh, bt3 } = Drone;
		const { body } = bodyMesh.userData;
		bt3.setValue(0, AP.gravity, 0);
		body.setGravity(bt3);
	}

	/** @param {"up"|"down"|"stop"} dir */
	static verticalBoost(dir) {
		const { bt3, bodyMesh, upVelocity, downVelocity } = Drone;
		const { body } = bodyMesh.userData;

		const vel = dir === 'up' ? upVelocity : dir === 'down' ? downVelocity : 0;

		bt3.setValue(0, vel, 0);
		body.setLinearVelocity(bt3);
	}

	static move() {
		World.droneView();

		document.addEventListener('keydown', (e) => {
			const { armed, keys } = Drone;

			if (!armed) return;
			if (!keyCodes.includes(e.code)) return;

			// Update active key to true
			keys[toKey(e.code)] = true;

			if (keys.w) Drone.forward(true);
			if (keys.s) Drone.reverse(true);

			if (keys.a) Drone.left(true);
			if (keys.d) Drone.right(true);

			if (keys.i) Drone.verticalBoost('up');
			if (keys.k) Drone.verticalBoost('down');
		});

		document.addEventListener('keyup', (e) => {
			if (!keyCodes.includes(e.code)) return;

			// Update key to false
			Drone.keys[toKey(e.code)] = false;

			if (e.code === 'KeyW') Drone.forward(false);
			if (e.code === 'KeyS') Drone.reverse(false);
			if (e.code === 'KeyA') Drone.left(false);
			if (e.code === 'KeyD') Drone.right(false);

			if (e.code === 'KeyI') Drone.verticalBoost('stop');
			if (e.code === 'KeyK') Drone.verticalBoost('stop');
		});
	}

	static left(turn = true) {
		const { bt3, bodyMesh, yawSpeed } = Drone;
		const { body } = bodyMesh.userData;

		bt3.setValue(0, turn ? yawSpeed : 0, 0);
		body.setAngularVelocity(bt3);
	}

	static right(turn = true) {
		const { bt3, bodyMesh, yawSpeed } = Drone;
		const { body } = bodyMesh.userData;

		bt3.setValue(0, turn ? -yawSpeed : 0, 0);
		body.setAngularVelocity(bt3);
	}

	static forward(move = true) {
		const { v3, bt3, droneGroup, speed, bodyMesh, keys } = Drone;
		const { body } = bodyMesh.userData;

		let vy = 0;
		if (keys.i) vy += Drone.upVelocity;
		if (keys.k) vy += Drone.downVelocity;

		v3.set(0, 0, 1)
			.applyQuaternion(droneGroup.quaternion)
			.multiplyScalar(speed);

		bt3.setValue(move ? v3.x : 0, move ? vy + v3.y : 0, move ? v3.z : 0);
		body.setLinearVelocity(bt3);
	}

	static reverse(move = true) {
		const { v3, bt3, droneGroup, speed, bodyMesh: droneMass } = Drone;
		const body = droneMass.userData.body;

		v3.set(0, 0, -1)
			.applyQuaternion(droneGroup.quaternion)
			.multiplyScalar(speed);

		bt3.setValue(move ? v3.x : 0, move ? v3.y : 0, move ? v3.z : 0);
		body.setLinearVelocity(bt3);
	}

	static addDroneMass() {
		const geo = [0.4, 0.05, 0.4];
		const material = new MeshPhongMaterial({
			transparent: true,
			opacity: 0,
		});

		// const params = [...geo, Drone.droneMass, pos, quat, material];
		// const droneMass = AP.createPWP(...params);
		const bodyMesh = Shapes.box(...geo, pos, quat, material, 2);

		bodyMesh.castShadow = false;
		bodyMesh.receiveShadow = false;
		// droneMass.position.set(-10, 0, -10);

		const { body } = bodyMesh.userData;

		// Rotate the drone toward the castle
		const angleInRadians = (30 * Math.PI) / 180;
		const rotationQuaternion = new AP.Ammo.btQuaternion();
		rotationQuaternion.setEulerZYX(0, angleInRadians, 0);
		const transform = new AP.Ammo.btTransform();
		body.getMotionState().getWorldTransform(transform);
		transform.setRotation(rotationQuaternion);
		body.setWorldTransform(transform);

		// Set the position of the drone
		const position = new AP.Ammo.btVector3(-1, 4, -5);
		body.getWorldTransform().setOrigin(position);
		body.setAngularFactor(0, 1, 0);
		AP.Ammo.destroy(position);

		// const rotation = new AP.Ammo.btVector3(0, (30 * Math.PI) / 180, 0);
		// body.getWorldTransform().setRotation(rotation);
		// AP.Ammo.destroy(rotation);

		// Remove gravity from the drone
		Drone.bt3.setValue(0, 0, 0);
		body.setGravity(Drone.bt3);

		Drone.bodyMesh = bodyMesh;
		// console.log(body);
	}

	static animate() {
		const {
			keys: { w, s, a, d },
			rollAngle,
			armed,
			drone,
			droneGroup,
			tiltGroup,
			mixer,
			bodyMesh,
		} = Drone;

		if (mixer) Drone.mixer.update(clock.getElapsedTime());

		if (!drone) return;

		// Level out tilt group when specific keys not pressed
		if (!w && !s) {
			gsap.to(tiltGroup.rotation, { x: 0, duration: 0.2 });
		}
		
		if (!a && !d) {
			gsap.to(tiltGroup.rotation, { z: 0, duration: 0.2 });
		}

		// Update drone mass object
		if (bodyMesh && droneGroup) {
			const { body } = Drone.bodyMesh.userData;

			const transform = body.getWorldTransform();
			const origin = transform.getOrigin();
			const rotation = transform.getRotation();

			droneGroup.position.set(origin.x(), origin.y(), origin.z());
			quat.set(rotation.x(), rotation.y(), rotation.z(), rotation.w());
			droneGroup.rotation.setFromQuaternion(quat);
		}

		// Forward
		if (w && armed) {
			Drone.forward(true);
			gsap.to(tiltGroup.rotation, { 
				x: rollAngle, 
				duration: 0.2 
			});
		}

		if (s && armed) {
			Drone.reverse(true);
			gsap.to(tiltGroup.rotation, { 
				x: -rollAngle, 
				duration: 0.2 
			});
		}

		if (bodyMesh && droneGroup && armed) {
			// Yaw left
			if (a) {
				gsap.to(tiltGroup.rotation, { 
					z: -rollAngle, 
					duration: 0.2 
				});
			}

			// Yaw right
			if (d) {
				gsap.to(tiltGroup.rotation, { 
					z: rollAngle, 
					duration: 0.2 
				});
			}
		}
	}
}
