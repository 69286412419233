import { Vector3, MeshPhongMaterial } from 'three';
import { Shapes } from '../geometries/Shapes';

// Pit
const origin = new Vector3(50, 0, 137);
const height = 1.5;
const length = 10;
const width = 8;
const thickness = 0.5;

// Tilt parameters
const tiltAmount = -0.8; 

// Balls
const ballCount = 180;
const ballRadius = 0.65;

export class Ballpit {
	static init() {
		Ballpit.createWalls();
		Ballpit.createBalls();
	}

	static createWalls() {
		const wall = new MeshPhongMaterial({ color: 0x5e2d0c });
		const stake = new MeshPhongMaterial({ color: 0xeacb68 });

		const { x, z } = origin;
		// Tilt the ballpit by lowering pos3 and pos2 positions
		const pos1 = new Vector3(x - width / 2, height / 2, z);
		const pos2 = new Vector3(x + width / 2, height / 2, z);
		const pos3 = new Vector3(x, height / 2 - tiltAmount, z + length / 2); // Lower this end
		const pos4 = new Vector3(x, height / 2, z - length / 2);

		// Create arrays for spreading
		const dims1 = [null, wall, 0, 0.1];
		// End wall dimensions 
		const endWallDims = [width, height, thickness];
		
		// Tilted walls - long sides
		// Wall 1 - Left side (not tilted, just rotated)
		const wall1 = Shapes.box(thickness, height, length, pos1, ...dims1);
		wall1.rotation.x = Math.atan2(tiltAmount, length);
		const wall1Upper = Shapes.box(thickness, height, length, pos1, ...dims1);
		wall1Upper.rotation.x = Math.atan2(tiltAmount, length);
		wall1Upper.position.y += height * 1.1;

		// Wall 2 - Right side (not tilted, just rotated)
		const wall2 = Shapes.box(thickness, height, length, pos2, ...dims1);
		wall2.rotation.x = Math.atan2(tiltAmount, length);
		const wall2Upper = Shapes.box(thickness, height, length, pos2, ...dims1);
		wall2Upper.rotation.x = Math.atan2(tiltAmount, length);
		wall2Upper.position.y += height * 1.1;

		// Wall 3 - Back end (lowered)
		// Using endWallDims for wall3
		Shapes.box(...endWallDims, pos3, ...dims1);
		Shapes.box(...endWallDims, pos3, ...dims1).translateY(height * 1.1);

		// Wall 4 - Front end (regular height)
		// Using endWallDims for wall4
		Shapes.box(...endWallDims, pos4, ...dims1);
		Shapes.box(...endWallDims, pos4, ...dims1).translateY(height * 1.1);

		// Stakes - adjust positions to match tilted walls
		const stakeWidth = 0.3; // Increased stake width
		const stakeDims1 = [stakeWidth, height * 2 * 1.2, stakeWidth];
		const stakeDims2 = [null, stake, 0, 0.1];
		const tOffset = thickness / 2 + stakeWidth / 2;
		const hOffset = height / 2;
		
		// Adjust some stake positions to match the tilted walls
		const positions = [
			[pos1.x - tOffset, pos1.y + hOffset, pos1.z + length / 4],
			[pos1.x - tOffset, pos1.y + hOffset, pos1.z - length / 4],
			[pos2.x + tOffset, pos2.y + hOffset, pos2.z + length / 4],
			[pos2.x + tOffset, pos2.y + hOffset, pos2.z - length / 4],
			[pos3.x + width / 4, pos3.y + hOffset, pos3.z + tOffset], // Lowered stake
			[pos3.x - width / 4, pos3.y + hOffset, pos3.z + tOffset], // Lowered stake 
			[pos4.x + width / 4, pos4.y + hOffset, pos4.z - tOffset],
			[pos4.x - width / 4, pos4.y + hOffset, pos4.z - tOffset],
		];

		const stakePos = new Vector3();
		positions.forEach((position) => {
			stakePos.set(position[0], position[1], position[2]);
			Shapes.box(...stakeDims1, stakePos, ...stakeDims2);
		});
	}

	static createBalls() {
		// Use the center of the pit as the reference point
		const pitCenter = origin.clone();
		
		// Define the drop area dimensions (slightly smaller than the pit)
		const dropWidth = width * 0.8; // Reduced from 0.85 to account for thicker walls
		const dropLength = length * 0.8; // Reduced from 0.85 to account for thicker walls
		
		// Create materials for the different colored balls
		const materials = [
			new MeshPhongMaterial({ color: 0xff6666 }),
			new MeshPhongMaterial({ color: 0xaadd66 }),
			new MeshPhongMaterial({ color: 0x77ddee }),
			new MeshPhongMaterial({ color: 0xaaaaff }),
			new MeshPhongMaterial({ color: 0xdddd77 }),
		];

		// Drop balls with staggered timing
		for (let i = 0; i < ballCount; i++) {
			// Random position within the drop area
			const x = (Math.random() - 0.5) * dropWidth;
			const z = (Math.random() - 0.5) * dropLength;
			
			// Random height above the pit (6 to 11 meters)
			const y = 6 + Math.random() * 5;
			
			// Calculate final drop position
			const dropPos = pitCenter.clone().add(new Vector3(x, y, z));
			
			// Choose random material
			const randomNumber = Math.floor(Math.random() * materials.length);
			const mat = materials[randomNumber];
			
			// Stagger the dropping of balls over time (up to 8 seconds)
			setTimeout(() => {
				const ball = Shapes.sphere(
					ballRadius,
					dropPos,
					null,
					mat,
					0.1,
					0.06
				);
				
				const { body } = ball.userData;
				body.setRollingFriction(0.06);
				body.setRestitution(0.7);
				
				// Add slight random impulse for variety
				const impulseStrength = 0.5;
				const randomImpulse = new Vector3(
					(Math.random() - 0.5) * impulseStrength,
					0,
					(Math.random() - 0.5) * impulseStrength
				);
				
				const btVec = new window.Ammo.btVector3(
					randomImpulse.x, 
					randomImpulse.y, 
					randomImpulse.z
				);
				
				body.applyCentralImpulse(btVec);
				window.Ammo.destroy(btVec);
				
			}, i * (8000 / ballCount)); // Spread ball creation over 8 seconds
		}
	}
}
