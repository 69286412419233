import { useEffect, useRef, useState } from 'react';
import './App.css';
import { World } from './scene/World';
// import { Drone } from './scene/Drone';
import MobileControls from './components/MobileControls';
import { AP } from './scene/AmmoPhysics';

function App() {
	const sceneRef = useRef(null);
	const [initError, setInitError] = useState(null);

	useEffect(() => {
		if (window.location.pathname.includes('/cv')) {
			window.location.href =
				'https://webzeppelin.notion.site/Steve-Richardson-1db37075f4564643bab49e57f38aa241?pvs=4';
		}

		const setup = async () => {
			if (sceneRef === null) return;

			try {
				// Suppress console errors in development environment
				const originalConsoleError = console.error;
				console.error = (...args) => {
					// Filter out known Ammo.js initialization errors
					if (args[0] && typeof args[0] === 'string' && 
						(args[0].includes('Ammo') || 
						 args[0].includes('btVector3') || 
						 args[0].includes('emscripten'))) {
						return;
					}
					originalConsoleError(...args);
				};

				await AP.init().catch(err => {
					console.warn("Error initializing Ammo.js, retrying silently:", err);
					// If there's an error, try again after a short delay
					return new Promise(resolve => {
						setTimeout(async () => {
							try {
								await AP.init();
								resolve();
							} catch (error) {
								console.warn("Second attempt failed:", error);
								setInitError("Failed to initialize physics engine");
								resolve();
							}
						}, 1000);
					});
				});
				
				// If we got here without errors, initialize the world
				World.init('scene');

				// Restore console.error
				console.error = originalConsoleError;
			} catch (error) {
				console.error("Fatal setup error:", error);
				setInitError("Failed to initialize the scene");
			}
		};
		setup();
	}, [sceneRef]);

	return (
		<div id='scene' ref={sceneRef} className='App'>
			{initError && (
				<div style={{
					position: 'absolute',
					top: '10px',
					left: '10px',
					background: 'rgba(255,0,0,0.7)',
					color: 'white',
					padding: '10px',
					borderRadius: '5px',
					zIndex: 1000
				}}>
					{initError}
				</div>
			)}
			<video
				id='video'
				playsInline
				webkit-playsinline='true'
				muted
				// loop
				autoPlay
				width='320'
				height='240'
				src='videos/duffelbag-promo.mp4'
				style={{ display: 'none' }}
			></video>
			<MobileControls />

			{/* <div
				style={{
					display: 'flex',
					textAlign: 'left',
					flexDirection: 'column',
					position: 'absolute',
					right: '5px',
					bottom: '5px',
					backgroundColor: 'rgba(255,255,255,0.6)',
					padding: '4px',
					borderRadius: '4px',
				}}
			>
				<span>Toggle Camera Mode: F</span>
				<span>Arm: Space</span>
				<span>Move: WASD</span>
				<span>Elevation: JL</span>
				<span>Shoot: I</span>
			</div> */}
		</div>
	);
}

export default App;
