import { MeshPhongMaterial, Quaternion, Vector3 } from 'three';
import { Shapes } from '../geometries/Shapes';

// Helpers
const rad = (deg) => deg * (Math.PI / 180);

// Position and rotation constants
const pos = new Vector3();
const quat = new Quaternion();
const AXIS_OF_ROTATION = new Vector3(0, 1, 0);

// Positioning constants
const CENTER = new Vector3(120, 0, 88);

// Dimensions constants
const BLOCK_WIDTH = 9;
const BLOCK_HEIGHT = 3;
const BLOCK_DEPTH = 2;
const COLISEUM_RADIUS = 10;
const DEGREE_INCREMENT = 60;
const LEVELS = 35;
const START_COLISEUM_EFFECT = 30;

// Make an array of 10 different rainbow materials
const COLISEUM_MATERIALS_1 = [
	new MeshPhongMaterial({ color: 0xffff77 }),
	new MeshPhongMaterial({ color: 0x7777ff }),
	new MeshPhongMaterial({ color: 0x77ff77 }),
	new MeshPhongMaterial({ color: 0xff7777 }),
	new MeshPhongMaterial({ color: 0xff77ff }),
	new MeshPhongMaterial({ color: 0x77ffff }),
	new MeshPhongMaterial({ color: 0x777777 }), 
];
const COLISEUM_MATERIALS_2 = [
	new MeshPhongMaterial({ color: 0x77ff77 }),
	new MeshPhongMaterial({ color: 0x77ff77 }),
	new MeshPhongMaterial({ color: 0x77ff77 }),
	new MeshPhongMaterial({ color: 0x77ff77 }),
	new MeshPhongMaterial({ color: 0x77ff77 }),
	new MeshPhongMaterial({ color: 0x77ff77 }),
	new MeshPhongMaterial({ color: 0x77ff77 }), 
];

const COLISEUM_MATERIALS = Math.random() < 0.5 ? COLISEUM_MATERIALS_1 : COLISEUM_MATERIALS_2;


export class Coliseum {
	static init() {
		let removeCount = 0;
		let alternate = false;

		for (let level = 0; level < LEVELS; level++) {
			const height = level * (BLOCK_HEIGHT + 0.01) + 0.5 * BLOCK_HEIGHT;
			alternate = !alternate;
			if (level >= START_COLISEUM_EFFECT) removeCount += 1;

			for (let deg = 0; deg < 360; deg = deg + DEGREE_INCREMENT) {
				const step = alternate ? deg : deg + DEGREE_INCREMENT / 2;

				// Coliseum effect
				if (level > START_COLISEUM_EFFECT) {
					const bound = 0 + DEGREE_INCREMENT * removeCount;
					if (deg <= bound && deg >= 360 - bound) continue;
				}

				// Set block rotation
				const angleOfRotation = 90 - step;
				quat.setFromAxisAngle(AXIS_OF_ROTATION, rad(angleOfRotation));

				const x = Math.cos(rad(step)) * COLISEUM_RADIUS;
				const y = Math.sin(rad(step)) * COLISEUM_RADIUS;
				pos.set(x, height, y).add(CENTER);
				Shapes.box(BLOCK_WIDTH, BLOCK_HEIGHT, BLOCK_DEPTH, pos, quat, 
					COLISEUM_MATERIALS[Math.floor(Math.random() * COLISEUM_MATERIALS.length)], 
					5, 
					10
				);
			}
		}

		// Cap
		// const capHeight = BLOCK_HEIGHT * 5;
		// pos.set(0, LEVELS * BLOCK_HEIGHT + capHeight / 2, 0).add(CENTER);
		// quat.setFromAxisAngle(new Vector3(1, 0, 0), Math.PI);

		// Shapes.cone(COLISEUM_RADIUS + BLOCK_WIDTH / 2, capHeight, pos, quat, COLISEUM_MATERIAL, 60, 0.5);
	}
}
