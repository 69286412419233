import React, { useEffect, useState, useRef } from 'react';
import { Drone } from '../scene/Drone';
import gsap from 'gsap';

const isMobileOrSmallScreen = () => {
  return window.innerWidth <= 800 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const Joystick = ({ onMove, onEnd, size = 100, className = '', verticalOnly = false, id }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const joystickRef = useRef(null);
  const touchIdRef = useRef(null);
  const isTouchRef = useRef(false);

  const getPositionFromEvent = (e) => {
    if (!joystickRef.current) return null;
    
    const rect = joystickRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    
    let clientX, clientY;
    if (isTouchRef.current) {
      // Find the specific touch for this joystick
      let touchFound = false;
      for (let i = 0; i < e.touches.length; i++) {
        const touch = e.touches[i];
        if (touch.identifier === touchIdRef.current) {
          clientX = touch.clientX;
          clientY = touch.clientY;
          touchFound = true;
          break;
        }
      }
      if (!touchFound) return null;
    } else {
      clientX = e.clientX;
      clientY = e.clientY;
    }
    
    return {
      x: clientX - centerX,
      y: clientY - centerY
    };
  };

  const handleStart = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (e.type.includes('touch')) {
      // Store the touch identifier for this joystick
      isTouchRef.current = true;
      
      // Find a touch that is within this joystick's bounds
      let foundTouch = false;
      for (let i = 0; i < e.touches.length; i++) {
        const touch = e.touches[i];
        const rect = joystickRef.current.getBoundingClientRect();
        if (touch.clientX >= rect.left && touch.clientX <= rect.right && 
            touch.clientY >= rect.top && touch.clientY <= rect.bottom) {
          
          // Make sure this touch isn't already being tracked by another joystick
          const tracked = document.querySelectorAll('.joystick[data-active="true"]');
          let isAlreadyTracked = false;
          
          for (const el of tracked) {
            if (el !== joystickRef.current && el.getAttribute('data-touch-id') === touch.identifier.toString()) {
              isAlreadyTracked = true;
              break;
            }
          }
          
          if (!isAlreadyTracked) {
            touchIdRef.current = touch.identifier;
            joystickRef.current.setAttribute('data-touch-id', touch.identifier);
            joystickRef.current.setAttribute('data-active', 'true');
            foundTouch = true;
            break;
          }
        }
      }
      
      if (!foundTouch) return;
    } else {
      isTouchRef.current = false;
    }
    
    setIsDragging(true);
    const pos = getPositionFromEvent(e);
    if (!pos) return;
    
    const { x, y } = pos;
    const distance = Math.sqrt(x * x + y * y);
    const maxDistance = size / 2 - 20;
    
    if (distance <= maxDistance) {
      setPosition({ x, y });
      onMove(x / maxDistance, y / maxDistance);
    }
  };

  const handleMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    e.stopPropagation();
    
    const pos = getPositionFromEvent(e);
    if (!pos) return;
    
    const { x, y } = pos;
    const distance = Math.sqrt(x * x + y * y);
    const maxDistance = size / 2 - 20;
    
    let newX = x;
    let newY = y;
    
    if (distance > maxDistance) {
      const angle = Math.atan2(y, x);
      newX = Math.cos(angle) * maxDistance;
      newY = Math.sin(angle) * maxDistance;
    }

    if (verticalOnly) {
      newX = 0;
    }
    
    setPosition({ x: newX, y: newY });
    onMove(newX / maxDistance, newY / maxDistance);
  };

  const handleEnd = (e) => {
    if (isTouchRef.current) {
      // Check if this is the touch we're tracking
      let touchFound = false;
      for (let i = 0; i < e.changedTouches.length; i++) {
        if (e.changedTouches[i].identifier === touchIdRef.current) {
          touchFound = true;
          break;
        }
      }
      if (!touchFound) return;
      
      joystickRef.current.removeAttribute('data-touch-id');
      joystickRef.current.setAttribute('data-active', 'false');
    }
    
    setIsDragging(false);
    setPosition({ x: 0, y: 0 });
    touchIdRef.current = null;
    isTouchRef.current = false;
    onEnd();
  };

  useEffect(() => {
    const handleTouchCancel = (e) => {
      for (let i = 0; i < e.changedTouches.length; i++) {
        if (e.changedTouches[i].identifier === touchIdRef.current) {
          setIsDragging(false);
          setPosition({ x: 0, y: 0 });
          touchIdRef.current = null;
          isTouchRef.current = false;
          onEnd();
          break;
        }
      }
    };

    const handleGlobalEnd = (e) => {
      if (isDragging && !isTouchRef.current) {
        handleEnd(e);
      }
    };

    window.addEventListener('touchend', handleGlobalEnd);
    window.addEventListener('touchcancel', handleTouchCancel);
    window.addEventListener('mouseup', handleGlobalEnd);
    
    return () => {
      window.removeEventListener('touchend', handleGlobalEnd);
      window.removeEventListener('touchcancel', handleTouchCancel);
      window.removeEventListener('mouseup', handleGlobalEnd);
	};
	   // eslint-disable-next-line
  }, [isDragging, onEnd]);

  return (
    <div 
      ref={joystickRef}
      className={`joystick ${className}`}
      data-active="false"
      data-joystick-id={id}
      onTouchStart={handleStart}
      onTouchMove={handleMove}
      onTouchEnd={handleEnd}
      onTouchCancel={handleEnd}
      onMouseDown={handleStart}
      onMouseMove={handleMove}
      onMouseUp={handleEnd}
      style={{
        width: verticalOnly ? size * 0.4 : size,
        height: size,
        position: 'relative',
        touchAction: 'none',
        userSelect: 'none',
        isolation: 'isolate',
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        WebkitHighlightColor: 'rgba(0,0,0,0)',
        WebkitUserDrag: 'none',
        touchAction: 'manipulation'
      }}
    >
      <div
        className="joystick-track"
        style={{
          width: '100%',
          height: '100%',
          borderRadius: verticalOnly ? '20px' : '50%',
          background: 'rgba(255, 255, 255, 0.3)',
          backdropFilter: 'blur(10px)',
          position: 'relative'
        }}
      />
      <div
        className="joystick-handle"
        style={{
          width: 40,
          height: 40,
          borderRadius: '50%',
          background: 'rgba(255, 255, 255, 0.8)',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: `translate(${position.x}px, ${position.y}px) translate(-50%, -50%)`,
          transition: isDragging ? 'none' : 'transform 0.1s ease-out',
          cursor: 'pointer'
        }}
      />
    </div>
  );
};

const ShootButton = ({ onShoot }) => {
  const buttonRef = useRef(null);
  const [isPressed, setIsPressed] = useState(false);
  
  const handleTouchStart = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsPressed(true);
    onShoot();
  };
  
  const handleTouchEnd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsPressed(false);
  };
  
  return (
    <div
      ref={buttonRef}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={() => {
        setIsPressed(true);
        onShoot();
      }}
      onMouseUp={() => setIsPressed(false)}
      className="shoot-button"
      style={{
        transform: isPressed ? 'scale(0.9)' : 'scale(1)',
        background: isPressed ? 'rgba(255, 0, 0, 0.5)' : 'rgba(255, 0, 0, 0.2)',
        borderRadius: '50%',
        width: '70px',
        height: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '30px',
        boxShadow: isPressed ? '0 0 10px rgba(255, 0, 0, 0.3)' : '0 0 15px rgba(255, 0, 0, 0.2)',
        transition: 'all 0.1s ease',
        cursor: 'pointer'
      }}
    >
      🔥
    </div>
  );
};

const MobileControls = () => {
  const [showControls, setShowControls] = useState(isMobileOrSmallScreen());

  useEffect(() => {
    const handleResize = () => {
      setShowControls(isMobileOrSmallScreen());
    };

    window.addEventListener('resize', handleResize);
    
    // Add styles to the document
    const style = document.createElement('style');
    style.textContent = `
      .mobile-controls {
        position: fixed;
        bottom: 20px;
        left: 20px;
        right: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        z-index: 1000;
        pointer-events: none;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
      }

      .joystick {
        pointer-events: all;
        cursor: pointer;
        touch-action: manipulation;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-highlight-color: rgba(0,0,0,0);
        -webkit-user-drag: none;
      }

      .joystick-handle {
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-highlight-color: rgba(0,0,0,0);
      }

      .joystick-track {
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
      }

      .joystick-handle:active {
        background: rgba(255, 255, 255, 1);
      }

      .right-controls {
        display: flex;
        flex-direction: column;
        align-items: center;
        pointer-events: all;
        margin-right: 20px;
        position: relative;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
      }

      .shoot-button {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: rgba(255, 0, 0, 0.2);
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1001;
        pointer-events: all;
        font-size: 32px;
        padding: 0;
        margin: 0 0 20px 0;
        box-shadow: 0 0 15px rgba(255, 0, 0, 0.2);
        position: relative;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
      }

      .shoot-button:active {
        background: rgba(255, 0, 0, 0.5);
        transform: scale(0.95);
      }
    `;
    document.head.appendChild(style);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.head.removeChild(style);
    };
  }, []);

  if (!showControls) return null;

  const handleLeftJoystick = (x, y) => {
    // Left joystick controls forward/backward and left/right
    if (Math.abs(x) > 0.3) {
      // Left/right movement (yaw)
      if (x > 0) {
        Drone.right(true);
        // Add roll effect for yaw using tiltGroup (same as keyboard controls)
        gsap.to(Drone.tiltGroup.rotation, { z: Drone.rollAngle, duration: 0.2 });
      } else {
        Drone.left(true);
        // Add roll effect for yaw using tiltGroup (same as keyboard controls)
        gsap.to(Drone.tiltGroup.rotation, { z: -Drone.rollAngle, duration: 0.2 });
      }
    } else {
      Drone.right(false);
      Drone.left(false);
      // Reset roll when not yawing using GSAP
      gsap.to(Drone.tiltGroup.rotation, { z: 0, duration: 0.2 });
    }

    if (Math.abs(y) > 0.3) {
      // Forward/backward movement (reversed direction)
      if (y > 0) {
        Drone.reverse(true);
        gsap.to(Drone.tiltGroup.rotation, { x: -Drone.rollAngle, duration: 0.2 });
      } else {
        Drone.forward(true);
        gsap.to(Drone.tiltGroup.rotation, { x: Drone.rollAngle, duration: 0.2 });
      }
    } else {
      Drone.forward(false);
      Drone.reverse(false);
      gsap.to(Drone.tiltGroup.rotation, { x: 0, duration: 0.2 });
    }
  };

  const handleRightJoystick = (x, y) => {
    // Right joystick controls elevation only
    if (Math.abs(y) > 0.3) {
      // Elevation (up/down)
      if (y > 0) Drone.verticalBoost('down');
      else Drone.verticalBoost('up');
    } else {
      Drone.verticalBoost('stop');
    }
  };

  const handleShoot = () => {
    // Call the shoot method directly instead of simulating key events
    Drone.shoot();
  };

  const stopAllMovement = () => {
    Drone.forward(false);
    Drone.reverse(false);
    Drone.left(false);
    Drone.right(false);
    Drone.verticalBoost('stop');
  };

  return (
    <div className="mobile-controls">
      <div style={{ position: 'relative' }}>
        <Joystick 
          onMove={handleLeftJoystick}
          onEnd={stopAllMovement}
          size={120}
          id="left"
        />
      </div>
      <div className="right-controls">
        <ShootButton onShoot={handleShoot} />
        <div style={{ position: 'relative' }}>
          <Joystick 
            onMove={handleRightJoystick}
            onEnd={() => Drone.verticalBoost('stop')}
            size={120}
            verticalOnly={true}
            id="right"
          />
        </div>
      </div>
    </div>
  );
};

export default MobileControls; 